/* eslint-disable no-unused-vars */
import Banner from 'src/components/Estaticos/Banner/Banner.vue'
import VeiculoResultado from 'src/components/VeiculoResultado.vue'
import BiitrixModel from 'src/model/usuario/BiitrixModel'
import VeiculosEntradaModel from 'src/model/veiculo/VeiculosEntradaModel'
import CredereLeadModel from 'src/model/usuario/CredereLeadModel'
import CredereSimulacaoModel from 'src/model/usuario/CredereSimulacaoModel'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'
import ConsultaPlacaIcarrosModel from 'src/model/veiculo/ConsultaPlacaIcarrosModel'
import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import usuarioLoginStore from 'src/store/usuario-login'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import LogradouroCepModel from 'src/model/cep/LogradouroCepModel.js'
import vTopFilters from 'src/core/filters/vTopFilters'
import VeiculoCalcularPrecoModel from 'src/model/veiculo/VeiculoCalcularPrecoModel'
import AnuncioModel from 'src/model/veiculo/AnuncioModel'
import Carregando from 'src/components/CarregandoGeral/Carregando.vue'
import { LocalStorage, clone } from 'quasar'
import QRadioCustom from 'src/components/RadioCustom/QRadioCustom.vue'
import MarcaModel from 'src/model/veiculo/MarcaModel'
import ModeloModel from 'src/model/veiculo/ModeloModel'
import VersaoModel from 'src/model/veiculo/VersaoModel'
import CambioModel from 'src/model/veiculo/CambioModel'
import CombustivelModel from 'src/model/veiculo/CombustivelModel'
import DominioCredereModel from 'src/model/veiculo/DominioCredereModel'
import ConteudoModel from 'src/model/usuario/ConteudoModel'
import UsuarioModel from 'src/model/usuario/UsuarioModel'
import PrecificadorModel from 'src/model/veiculo/PrecificadorModel'
import CalcularValorParcelaModel from 'src/model/veiculo/CalcularValorParcelaModel'
import UsuarioCoreModel from 'src/core/model/UsuarioModel'
import Bancos from 'src/core/mixin/Bancos'
import axios from 'axios'
import { ref } from 'vue'
import CardTag from 'src/components/Card/CardTag.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Detalhes',
  components: {
    CardTag,
    VueSlickCarousel,
    Banner,
    Carregando,
    QRadioCustom,
    VeiculoResultado,
    VantagensOutros: () => import('src/components/VantagensOutros/VantagensOutros.vue'),
    VantagensOutlet: () => import('src/components/VantagensOutlet/VantagensOutlet.vue'),
    Depoimentos: () => import('src/components/Depoimentos/Depoimentos.vue'),
    ContatosTelefonicos: () => import('src/components/ContatosTelefonicos/ContatosTelefonicos.vue'),
    VeiculosCategoria: () => import('src/components/VeiculosCategoria/VeiculosCategoria.vue'),
    MaisBuscados: () => import('src/components/Estaticos/MaisBuscados/MaisBuscados.vue')
  },
  mixins: [NotificacaoMixin, EstadosBrasileiros, vTopFilters, Bancos],
  meta () {
    if (this.$store.getters['data/getData'] && this.$store.getters['data/getData'].modelo_objeto) {
      return {
        // title: this.loteComp.VEI_MOD_COMPLEMENTO + ' ' + this.loteComp.LEI_NOME + ' - Leilomaster', VEI_COMBUSTIVEL VEI_COR_BUSCA VEI_ANOMOD_BUSCA
        // this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + ' ' +
        title: this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + ' ' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ' ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + ' - NetCarros',
        description: {
          name: 'description',
          content: 'Parcelas a partir de ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf / 48) + '. ' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome + ' ' + this.$store.getters['data/getData'].versao_objeto.versao_nome + ' ' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ' ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + ' - NetCarros'
        },
        link: [{ rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath }],
        script: {
          ldJson: {
            type: 'application/ld+json',
            innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Product",
            "brand": {
              "@type": "Brand",
              "name": "` + this.$store.getters['data/getData'].marca_objeto.marca_nome + `"
            },
            "sku": "` + this.$store.getters['data/getData'].modelo_objeto.modelo_nome + `",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.netcarros.com.br"
            },
            "url" : "` + 'https://www.netcarros.com.br' + this.$route.fullPath + `",
            "offers": {
              "@type": "Offer",
              "availability": "` + 'https://schema.org/' + (this.$store.getters['data/getData'].modelo_objeto.modelo_nome === 'LIBERADO_LEILAO' ? 'InStock' : 'SoldOut') + `",
              "price": "` + this.$options.filters.dinheiroBancoDeDados(this.$store.getters['data/getData'].valor_venda_pf) + `",
              "priceCurrency": "BRL"
            },
            "name": "` + this.$store.getters['data/getData'].modelo_objeto.modelo_nome + ' - Netcarros' + `",
            "description": "` + (this.$store.getters['data/getData'].modelo_objeto.modelo_nome === 'RETIRADO' ? 'RETIRADO'
              : (this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + ' ' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome)) + `",
            "image": ["` + ' ' + `"],
            "owns": {
              "@type": "Organization",
              "name": "NetCarros",
              "email": "sac@netcarros.com.br",
              "telephone": "+55 (11) 5026-7656",
              "slogan" : "Seu carro está aqui",
              "url": "https://www.netcarros.com.br"
            }
          }`
          }
        },
        meta: [
          {
            'property': 'og:title',
            'content': this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + ' ' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + ' ' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ' ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + ' - Netcarros'
          },
          {
            'name': 'description',
            'content': 'Parcelas a partir de ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf / 48) + '. ' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome + ' ' + this.$store.getters['data/getData'].versao_objeto.versao_nome + ' ' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ' ' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + ' - Netcarros'
          },
          { 'property': 'revisit-after', 'content': '5 days' },
          {
            'itemprop': 'image',
            'content': this.$store.getters['data/getData'].bem_arquivos[0] ? this.$options.filters.imagemOriginal(this.arquivosUrl + this.$store.getters['data/getData'].bem_arquivos[0].url) : ''
          },
          {
            'property': 'og:image:secure_url',
            'content': this.$store.getters['data/getData'].bem_arquivos[0] ? this.$options.filters.imagemOriginal(this.arquivosUrl + this.$store.getters['data/getData'].bem_arquivos[0].url) : '',
            'vmid': 'og:image:secure_url'
          },
          {
            'property': 'og:image',
            'content': this.$store.getters['data/getData'].bem_arquivos[0] ? this.$options.filters.imagemOriginal(this.arquivosUrl + this.$store.getters['data/getData'].bem_arquivos[0].url) : '',
            'vmid': 'og:image'
          },
          { 'property': 'og:site_name', 'content': 'NetCarros' },
          { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
          { 'property': 'og:locale', 'content': 'pt_BR' }
        ]
      }
    }
  },
  setup () {
    return {
      slide: 1,
      navPos: 'right',
      // slide: ref(1),
      // navPos: ref('right'),
      navigationPositions: [
        { value: 'top', label: 'top' },
        { value: 'right', label: 'right' },
        { value: 'bottom', label: 'bottom (default)' },
        { value: 'left', label: 'left' }
      ]
    }
  },
  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (previousRoute && ['veiculos'].indexOf(previousRoute.name) >= 0) {
      // Usar o store do filtro anterior para passar dados da busca realizada anteriormente
    }
    if (!previousRoute) {
      // store.commit('cache/putLimpar')
      // cache da busca anterior somente ou evento
    }
    let modelElastic = new ElasticSearchModel()
    return modelElastic.buscarAnuncio(currentRoute.params.id).then((res) => {
      let dados = res.hits.hits && res.hits.hits.length > 0 ? res.hits.hits[0]._source : null
      if (dados.situacao_id === 20) {
        return redirect('/comprar')
        // return redirect('/veiculo-nao-encontrado')
      }
      // const arquivos360 = [
      //   { label: 'Fotos 360 Externas', url: '/uploads-app/OfGvYWyHBuXG8Rum-1603457400.webp' },
      //   { label: 'Fotos 360 Internas', url: '/uploads-app/JYn3WzCwCOiEZ9wm-1603457833.webp' }
      // ]
      const arquivos360 = []
      if (!dados.bem_arquivos) {
        dados.bem_arquivos = []
      }
      dados.bem_arquivos = dados.bem_arquivos.reduce((acc, atual) => {
        const obj = { url: atual }
        acc = acc.concat(obj)
        return acc
      }, [])
      dados.bem_arquivos = Object.assign([], [...arquivos360, ...dados.bem_arquivos])
      return store.commit('data/putData', dados)
    }).catch(error => {
      console.log('error preFetch: ', error)
    })
  },
  data () {
    return {
      mounted: false,
      showDiv: null,
      showDivMobile: null,
      ad_files: [],
      carregandoCarroceria: false,
      carregandoFaixaPreco: false,
      carregandoMaisVistos: false,
      tipoCard: 'vertical',
      full360: false,
      fullScreen: false,
      fullScreenAvarias: false,
      anunciosCarroceria: [],
      anunciosFaixaPreco: [],
      anunciosMaisVistos: [],
      anuncioSituacaoVendido: [9, 20],
      linkpage: '',
      mapsource: '',
      progress: 0.00,
      valorMinimoFinanciamento: 3000,
      buffer: 1,
      curto: true,
      windowheight: 0,
      tabsimulacao: 'simular',
      modelLogradouroCep: new LogradouroCepModel(),
      modelCalcularValorParcela: new CalcularValorParcelaModel(),
      modelElastic: new ElasticSearchModel(),
      simulacaoId: null,
      simulacaoIdCredere: null,
      encontrouSimulacao: false,
      limparVeiculoEntrada: true,
      data: null,
      placaLocalizada: false,
      prevenirMudancaTab: false,
      carroEntradaQuitado: true,
      conteudo: '',
      tipoConteudo: '',
      modalConteudo: false,
      carregandoConteudo: false,
      modal360: false,
      modal360posicao: 0,
      modal360inline: true,
      modelCalcularPreco: new VeiculoCalcularPrecoModel(),
      calculandoPreco: false,
      veiculosDisponiveisEntrada: [],

      modelBiitrix: new BiitrixModel(),

      forcerRender: 0,
      forcerRenderPage: 0,
      carregando: true,

      modelUsuario: new UsuarioModel(),
      cpfUsuarioSelecionado: null,
      pesquisandoUsuario: false,
      // logado: LocalStorage.getItem('logado'),
      usuarioSelecionado: LocalStorage.getItem('logado'),

      slide: 0,
      email: '',
      criandoLeadBiitrix: false,
      tab: 'detalhes',

      termosAceitos: false,

      criadoCredereLead: false,
      criadoBitrixLead: false,

      // Credere
      modelVeiculosEntrada: new VeiculosEntradaModel(),
      modelAnuncio: new AnuncioModel(),
      modelCredereLead: new CredereLeadModel(),
      modelCredereSimulacao: new CredereSimulacaoModel(),
      modelCredereSimulacaoBuscar: new CredereSimulacaoModel(),
      modelCredereProposta: new CrederePropostaModel(),

      possuiCarroEntrada: false,
      formaPagamento: 113,
      veiculoQuitado: false,
      veiculoSaldoDevedor: 0,
      passo1: true,
      passo2: false,
      passo3: false,
      passo4: false,
      salvandoPasso1: false,
      salvandoPasso2: false,
      salvandoPasso3: false,
      salvandoPasso4: false,

      usuarioCoreModel: new UsuarioCoreModel(),
      /* */

      // Listagens
      listaVersao: [],
      models: [],
      carregamentos: [],
      filtrosListagens: [],
      /* */

      sexoOptions: [
        {
          label: 'Masculino',
          value: 'M'
        },
        {
          label: 'Feminino',
          value: 'F'
        }
      ],

      termosPasso3: false,
      tempo: 20,

      ocupacaoOptions: [],
      profisaoOptions: [],
      combustiveisOptions: [],

      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 0,
        masked: false
      },
      diretivaKm: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },

      formPasso2: {
        valorExtra: 0
      },

      formPasso4: null,

      resultSimulacao: [],
      resultadosCalcularPreco: null,

      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#76528F',
        width: '5px',
        opacity: 0.75
      },
      carrosselCards: {
        // lazyLoad: 'ondemand',
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        rows: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: true,
        responsive: [
          {
            breakpoint: 599,
            settings: {
              arrows: true,
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    getTags () {
      const tags = []
      if (this.dados) {
        if (this.dados.situacao_id === 9 || this.dados.situacao_id === 20) {
          tags.push({ type: 'vendido', value: 'VENDIDO' })
          return tags
        }
        if (this.dados.km === 0) {
          tags.push({ type: 'zero', value: 'ZERO KM' })
        }
        if (this.recemChegado) {
          tags.push({ type: 'recem-chegado', value: 'RECÉM CHEGADO' })
        }

        if (this.dados.pronta_entrega) {
          tags.push({ type: 'pronta-entrega', value: 'GARANTIA FÁBRICA' })
        }
        if (this.dados.outlet) {
          tags.push({ type: 'outlet', value: 'OUTLET' })
        }
        if (this.dados.ipva_pago) {
          tags.push({ type: 'ipva-pago', value: 'IPVA PAGO' })
        }
        if (this.dados.aplicativo) {
          tags.push({ type: 'aplicativo', value: 'CARRO DE APP' })
        }
        if (this.dados.blackfriday) {
          tags.push({ type: 'blackfriday', value: 'BLACK FRIDAY' })
        }
        if (this.dados.cyberweek) {
          tags.push({ type: 'cyberweek', value: 'CYBER WEEK' })
        }
        if (this.dados.situacao_id === 6) {
          tags.push({ type: 'em-preparacao', value: 'EM PREPARAÇÃO' })
        }
        if (this.dados.valor_anterior_pf && this.dados.valor_anterior_pf < this.dados.valor_pf) {
          tags.push({ type: 'promocao', value: 'PROMOÇÃO' })
        }

        return tags
      }
    },
    files_avarias: {
      get () {
        return this.ad_files.filter(file => file.tipo === '8')
      }
    },
    logado: {
      get () {
        return this.$store.getters['usuario-login/get'] ?? null
      }
    },
    placaComputed: {
      get () {
        if (this.$store.getters['data/getData']) {
          return this.$store.getters['data/getData'].placa ?? this.$store.getters['data/getData'].spincar ?? false
        }
        return false
      }
    },
    valorVeiculoComp () {
      if (this.pcd) {
        return this.dados ? this.dados.valor_venda_pcd ?? this.dados.valor_venda_pf ?? 0 : 0
      }
      if (this.pj) {
        return this.dados ? this.dados.valor_venda_pj ?? this.dados.valor_venda_pf ?? 0 : 0
      }
      return this.dados ? (this.dados.valor_venda_pf && this.dados.valor_venda_pf > 0 ? this.dados.valor_venda_pf : 0) : 0
    },
    valorParcelaVeiculoComp () {
      return this.dados && this.dados.valor_parcelas ? this.dados.valor_parcelas : null
    },
    arquivosUrl () {
      return process.env.arquivosUrl
    },
    vendedorComp: {
      get: function () {
        return (this.logado && this.logado.id && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor'))
      }
    },
    usuarioSelecionadoComp: {
      get: function () {
        return this.usuarioSelecionado ? this.usuarioSelecionado : null
      },
      set: function (value) {
        this.usuarioSelecionado = Object.assign({}, this.usuarioSelecionado, value)
      }
    },
    valorEntradaComp: {
      get: function () {
        return this.possuiCarroEntrada ? (parseFloat(this.formPasso2.valorExtra) + parseFloat(this.valorEntradaVeiculoComp)) : parseFloat(this.formPasso2.valorExtra)
      }
    },
    valorEntradaVeiculoComp: {
      get: function () {
        return this.possuiCarroEntrada
          ? (this.veiculoQuitado ? parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
            : (parseFloat(this.veiculoSaldoDevedor) >= parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
              ? 0 : parseFloat(this.modelVeiculosEntrada.form.valor_mercado) - parseFloat(this.veiculoSaldoDevedor)))
          : 0
      }
    },
    dados: {
      get: function () {
        let dados = clone(this.$store.getters['data/getData'])

        function ordenar (a, b) {
          if (a) a = a[0].toUpperCase() + a.substr(1)
          if (b) b = b[0].toUpperCase() + b.substr(1)
          return a < b ? -1 : a > b ? 1 : 0
        }

        if (dados && dados.acessorios_busca_nome && dados.acessorios_busca_nome.length > 0) {
          dados.acessorios_busca_nome = dados.acessorios_busca_nome.sort(ordenar)
        }

        return dados
      },
      set: function (value) {
        this.data = Object.assign({}, this.data, value)
      }
    },
    resultSimulacaoOrder: {
      get () {
        function sortParcela (a, b) {
          return a.parcela < b.parcela ? -1 : a.parcela > b.parcela ? 1 : 0
        }

        let rsimulacao = this.resultSimulacao.sort(sortParcela)
        rsimulacao.forEach((item, index) => {
          if (item.parcela === 48) {
            this.parcelaSelecionada(item)
          }
        })
        return rsimulacao
      }
    }
  },
  watch: {
    '$q.screen.width':
  {
    handler (screen) {
      if (this.dados && (this.showDiv || this.showDivMobile)) {
        console.log('screen: ', screen)
        if (screen < 1024) {
          if (this.showDiv && !this.showDivMobile) {
            this.showDiv = false
            this.showDivMobile = true
          }
        } else {
          if (!this.showDiv && this.showDivMobile) {
            this.showDiv = true
            this.showDivMobile = false
          }
        }
      }
    }
  },
    '$route.params.id' (agora, antes) {
      this.forcerRenderPage++
    }
  },
  beforeRouteLeave (to, from, next) {
    if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
    //     document.querySelector('.botoes').classList.remove('hidden')
    //     document.querySelector('.b24-widget-button-wrapper').classList.remove('details-veiculo')
    //     document.querySelector('.q-page-container').classList.remove('q-page-container-0')
    //     document.querySelector('.q-header').classList.add('opacity-1')
    //     document.querySelector('.q-header').classList.remove('opacity-0')
      document.querySelector('.botoes a').href = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20saber%20com%20quais%20carros%20vocês%20trabalham?'
    }
    next()
  },
  mounted () {
    console.log('mounted')
    try {
      if (this.$q.screen.width < 1024) {
        this.showDiv = false
      } else {
        this.showDiv = true
      }

      if (this.$q.screen.width < 1024) {
        this.showDivMobile = true
      } else {
        this.showDivMobile = false
      }
      console.log('force render')
      this.forcerRenderPage++
      console.log('add event listener')
      window.addEventListener('keyup', event => {
        switch (event.keyCode) {
          case 39:
            if (this.$refs.carrossel) {
              this.$refs.carrossel.next()
            }
            break
          case 32:
            if (this.$refs.carrossel) {
              this.$refs.carrossel.next()
            }
            break
          case 37:
            if (this.$refs.carrossel) {
              this.$refs.carrossel.previous()
            }
            break
          default:
            break
        }
      })

      console.log('models')
      this.models = {
        marca_id: MarcaModel,
        modelo_id: ModeloModel,
        versao_id: VersaoModel,
        cambio_id: CambioModel,
        combustivel_id: CombustivelModel
      }

      // this.newModelBiitrix()
      // this.newModelCredereLead()
      // this.getDominiosCredere()
      console.log('getAnuncio - chamando')
      this.getAnuncio()

      // this.modelCredereLead.form.birthdate = this.usuarioSelecionado ? this.usuarioSelecionado.data_nascimento : null
      // if (this.usuarioSelecionado && this.usuarioSelecionado.documento_lead && this.usuarioSelecionado.documento_lead.credere_lead_id) {
      //   this.modelCredereLead.form.name = this.usuarioSelecionado.nome
      //   this.salvandoPasso3 = 'ok'
      // }
      console.log('nextTick')
      this.$nextTick(() => {
        console.log('nextTick - inicio')
        if (this.$route.hash) {
          this.$refs[this.$route.hash.substr(1, this.$route.hash.length) + (this.$q.screen.lt.sm ? `-mobile` : ``)].$el.click()
        }

        if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', '//integrator.swipetospin.com')
        // document.head.appendChild(recaptchaScript)
          document.querySelector('.q-header').classList.add('opacity-0')
          document.querySelector('.q-page-container').classList.add('q-page-container-0')
          // if (this.$q.screen.width < 768) {
          //   if (!this.$route.hash) {
          //     this.tabsimulacao = 'mensagem'
          //   }
          //   this.passo1 = true
          //   // document.querySelector('.botoes').classList.add('hidden')
          //   document.querySelector('.b24-widget-button-wrapper').classList.add('details-veiculo')
          // } else {
          //   // document.querySelector('.botoes').classList.remove('hidden')
          //   document.querySelector('.b24-widget-button-wrapper').classList.remove('details-veiculo')
          //   this.tabsimulacao = 'simular'
          // }
          setTimeout(() => {
            switch (this.$store.getters['data/getData'].proprietario_local_id) {
              case '4': // Barra Funda
                this.mapsource = 'https://www.google.com/maps/embed?hl=br&language=pt&pb=!1m18!1m12!1m3!1d3658.292383219665!2d-46.664260023789296!3d-23.521984060148412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5787a1014b3d%3A0xf58ab8d78c65d249!2sNetCarros%20-%20Barra%20Funda!5e0!3m2!1spt!2sbr!4v1708334443756!5m2!1spt!2sbr'
                this.linkpage = 'https://api.whatsapp.com/send?phone=5511950342154&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
                break
              case '6': // Bandeirantes
                this.mapsource = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.900699998051!2d-46.68076752378706!3d-23.60789396330505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce518a3d746fbd%3A0x5121f723abd32560!2sNetCarros%20-%20Bandeirantes!5e0!3m2!1spt!2sbr!4v1708368579082!5m2!1spt!2sbr'
                this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
                break
              case '7': // Campinas
                this.mapsource = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.364748780882!2d-47.037435388588904!3d-22.874310736625795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8c58dced779af%3A0x630fda52828c80e3!2sNetCarros%20-%20Campinas!5e0!3m2!1sen!2sbr!4v1743864910953!5m2!1sen!2sbr'
                this.linkpage = 'https://api.whatsapp.com/send?phone=5511950342154&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
                break
              case '9': // Brasília
                this.mapsource = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.2254400678958!2d-47.97714278813015!3d-15.79206248478485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a391893c6f7c3%3A0x405f5ffd78ae52cd!2sNetCarros%20-%20Bras%C3%ADlia!5e0!3m2!1spt!2sbr!4v1719978498545!5m2!1spt!2sbr'
                this.linkpage = 'https://api.whatsapp.com/send?phone=556196054535&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
                break
            }

            // foreach .btn-whatsapp set href
            document.querySelectorAll('.btn-whatsapp,.botoes a').forEach((item) => {
              item.href = this.linkpage
            })
          // document.querySelector('.btn-whatsapp').href = this.linkpage
          }, 1000)
        }
        console.log('nextTick - fim')
      })
    } catch (error) {
      console.log('error mounted: ', error)
    }
    console.log('mounted - fim')
    this.mounted = true
  },
  methods: {
    slickGoTo (index) {
      this.slide = index
      this.$refs.slick.goTo(index)
    },
    getYoutubeEmbedUrl (url) {
      return url.replace('watch?v=', 'embed/') + '?rel=0'
    },
    acesso () {
      console.log('acesso - inicio')
      let modelAnuncio = this.modelAnuncio.recurso
      console.log(this.dados.id)
      this.modelAnuncio.recurso = 'anuncios/' + this.dados.id + '/incrementar-acesso'
      this.modelAnuncio.put().then((data) => {
        console.log(data)
        this.modelAnuncio.recurso = modelAnuncio
      })
      let modelRecurso = this.modelVeiculosEntrada.recurso
      this.modelVeiculosEntrada.recurso = 'veiculo/acesso/' + this.dados.versao_objeto.versao_id + '/' + this.dados.modelo_objeto.modelo_id + '/' + this.dados.marca_objeto.marca_id

      this.modelVeiculosEntrada.getListagem().then(() => {
        console.log('acesso - fim')
        this.modelVeiculosEntrada.recurso = modelRecurso
      })
      this.modelVeiculosEntrada.recurso = modelRecurso
    },
    removecurto () {
      if (this.curto === false) {
        this.$refs.opcionais.classList.remove('curto')
        this.curto = true
      } else {
        this.$refs.opcionais.classList.add('curto')
        this.curto = false
      }
    },
    irHome () {
      this.$router.push({ name: 'home' })
    },
    scrollObserver (scroll) {
      const header = document.querySelector('.q-header')

      if (scroll.position > 100) {
        header.classList.add('opacity-1')
      } else {
        header.classList.remove('opacity-1')
      }
    },
    abrirModal (tipo) {
      this.modalConteudo = true
      switch (tipo) {
        case 'termos':
          this.tipoConteudo = 'Termos de Uso'
          this.buscarConteudo('regulamento-termos-de-uso')
          break
        case 'privacidade':
          this.tipoConteudo = 'Política de Privacidade'
          this.buscarConteudo('regulamento-politica-privacidade')
          break
        default:
          this.buscarConteudo('regulamento-politica-privacidade')
          break
      }
    },
    buscarConteudo (chave) {
      const model = new ConteudoModel()
      this.carregandoConteudo = true
      model.getListagem({ params: { search: `chave_interna:${chave}` } }).then((response) => {
        this.conteudo = response.dados[0].conteudo
        this.carregandoConteudo = false
      }).catch((error) => {
        this.notificacao('erro', error.msg)
        this.modalConteudo = false
        this.carregandoConteudo = false
      })
    },
    scrollView (ref) {
      if (this.$refs[ref]) {
        this.prevenirMudancaTab = true
        this.$refs[ref].scrollIntoView({ block: 'start', behavior: 'smooth' })
        setTimeout(() => {
          this.prevenirMudancaTab = false
        }, 3000)
      }
    },
    scrollPassive (e) {
      // if (this.$q.screen.lt.sm) {
      //   if (e > this.$refs[`carrossel`].$el.clientHeight) {
      //     this.$refs[`navegacao-mobile-ref`].classList.remove('hidden')
      //   } else {
      //     this.$refs[`navegacao-mobile-ref`].classList.add('hidden')
      //   }
      // }
    },
    scrollHandler (e) {
      // if (this.$refs.financiamento && this.$refs.inspecao) {
      //   let tab = this.tab
      //   const halfViewportHeight = 0
      //   if (e.position >= (this.$refs.inspecaoR.offsetTop - halfViewportHeight) && e.position < (this.$refs.financiamentoR.offsetTop - halfViewportHeight)) {
      //     tab = 'inspecao'
      //   }
      //   if (e.position >= (this.$refs.financiamentoR.offsetTop - halfViewportHeight)) {
      //     tab = 'financiamento'
      //   }
      //   if (e.position < (this.$refs.inspecaoR.offsetTop - halfViewportHeight)) {
      //     tab = 'detalhes'
      //   }
      //   if (!this.prevenirMudancaTab) {
      //     this.tab = tab
      //   }
      // }
      // if (this.$refs.painelFlutuante) {
      //   if (e.position > this.$refs.painelFlutuante.offsetTop) {
      //     this.$refs.painelFlutuante.classList.remove('fixo')
      //     this.$refs.painelFlutuante.classList.add('fixo')
      //   } else {
      //     this.$refs.painelFlutuante.classList.remove('fixo')
      //   }
      // }
    },
    scrollSimulacao () {
      // if (this.$refs.financiamento) {
      //   this.$refs.financiamento.scrollIntoView({ behavior: 'smooth', block: 'end' })
      // }
    },
    visualizarLaudo () {
      this.verLaudo = false
      if (typeof this.dados.laudo_arquivo === 'string') {
        window.open(process.env.arquivosUrl + this.dados.laudo_arquivo)
      }
    },
    newModelBiitrix () {
      this.modelBiitrix = new BiitrixModel(this.usuarioSelecionado)
      // if (this.$refs.formBitrix) {
      //   this.$refs.formBitrix.reset()
      // }
      // if (this.$refs.formBitrix2) {
      //   this.$refs.formBitrix2.reset()
      // }
    },

    newModelCredereLead () {
      this.modelCredereLead = new CredereLeadModel(this.usuarioSelecionado)
      // if (this.$refs.formCrederePasso3) {
      //   this.$refs.formCrederePasso3.reset()
      // }
    },

    getDominiosCredere () {
      const model = new DominioCredereModel()
      model.getListagem().then((response) => {
        const lista = response.dados
        this.ocupacaoOptions = lista['occupation'].sort(this.ordenarLabel)
        this.profisaoOptions = lista['profession'].sort(this.ordenarLabel)
        this.combustiveisOptions = lista['fuel-type'].sort(this.ordenarLabel)
      })
    },

    getTagType (index) {
      const tags = this.getTags
      return tags.length > index ? tags[index]?.type : ''
    },

    getAnuncio () {
      console.log('getAnuncio - inicio')
      this.carregando = true
      this.modelElastic.buscarAnuncio(this.$route.params.id).then((res) => {
        let dados = res.hits.hits && res.hits.hits.length > 0 ? res.hits.hits[0]._source : null
        dados.valor_mercado = this.valorVeiculo
        // const arquivos360 = [
        //   { label: 'Fotos 360 Externas', url: '/uploads-app/81odOruGlHWts3wd-1603475720.jpg' },
        //   { label: 'Fotos 360 Internas', url: '/uploads-app/ejEI1CDg6hswTUpZ-1603475685.jpg' }
        // ]
        const arquivos360 = []

        if (dados.bem_arquivos) {
          dados.bem_arquivos = dados.bem_arquivos.reduce((acc, atual) => {
            const obj = { url: atual }
            acc = acc.concat(obj)
            return acc
          }, [])
        } else {
          dados.bem_arquivos = [{ url: '/netcarros/9OGF4WSsQruRgcBv-1604928586.png' }]
        }
        // for (var idx = 0; idx < dados.file_id.length; idx++) {
        //   this.ad_files.push({
        //     id: dados.file_id[idx],
        //     url: dados.file_url[idx],
        //     tipo: dados.file_tipo[idx],
        //     prioridade: dados.file_prioridade[idx]
        //   })
        // }

        dados.bem_arquivos = Object.assign([], [...arquivos360, ...dados.bem_arquivos])
        // this.$store.commit('depois/putData', this.modelCredereLead.form)
        this.$store.commit('data/putData', dados)
        // 5511950342154
        if (this.$store.getters['data/getData'].proprietario_local_id === '9') {
          this.linkpage = 'https://api.whatsapp.com/send?phone=556196054535&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
        } else if (this.$store.getters['data/getData'].proprietario_local_id === '6') {
          this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].marca_objeto.marca_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
        } else {
          this.linkpage = 'https://api.whatsapp.com/send?phone=5511950342154&text=Olá%20acessei%20https://www.netcarros.com.br' + this.$route.fullPath + '%20e%20vi%20seu%20anúncio%20do%20veículo%20' + this.$store.getters['data/getData'].marca_objeto.marca_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].modelo_objeto.modelo_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].versao_objeto.versao_nome.replace(/[^0-9a-zA-Z ]/g, '') + '%20' + this.$store.getters['data/getData'].ano_fabricacao + '/' + this.$store.getters['data/getData'].ano_modelo + ',%20' + 'no%20valor%20de%20' + this.$options.filters.dinheiroBR(this.$store.getters['data/getData'].valor_venda_pf) + '%20da%20loja%20' + this.$store.getters['data/getData'].estoque_local.nome + '%20e%20gostaria%20de%20saber%20mais%20informações'
        }
        document.querySelector('.botoes a').href = this.linkpage
        console.log('getAnuncio - fim')
        this.carregando = false
        this.carregandoCarroceria = true

        this.carregandoFaixaPreco = true

        let modelElastic = new ElasticSearchModel()
        console.log('buscar anunciosCarroceria')
        modelElastic.buscar([
          { tipo: 'multiplo', termo: 'carroceria_objeto.carroceria_nome', label: 'Carroceria', valor: dados.carroceria_objeto.carroceria_nome },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 }
        ], null, 1, 4).then((retorno) => {
          this.anunciosCarroceria = retorno.hits.hits.map((item) => item._source)
          this.carregandoCarroceria = false
        }).catch((e) => console.log('e', e))
        console.log('buscar faixa de preco')
        modelElastic.buscar([{ tipo: 'range', termo: 'valor_venda_pf', label: 'Valor de Venda', valor: { min: (dados.valor_venda_pf - 5000), max: (dados.valor_venda_pf + 5000) } },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 }], null, 1, 4).then((retorno) => {
          this.anunciosFaixaPreco = retorno.hits.hits.map((item) => item._source)
          this.carregandoFaixaPreco = false
        }).catch((e) => console.log('e', e))
        console.log('acesso')
        this.acesso()
      }).catch(error => {
        this.notificacao('erro', error)
        this.carregando = false
      })
    },

    ordenarLabel (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    },
    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11 ? this.validarCPFCalculo(cpf.toString()) : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    irTopo () {
      // if (this.$refs.topo) {
      //   this.$refs.topo.scrollIntoView({ block: 'start', behavior: 'smooth' })
      // }
    },
    descer () {
      // if (this.$refs.containerPrincipal) {
      //   this.$refs.containerPrincipal.scrollIntoView({ block: 'start', behavior: 'smooth' })
      // }
    },

    criarLeadBiitrix (cancelarRedirect, passo) {
      if (this.criandoLeadBiitrix) {
        return false
      }
      this.criandoLeadBiitrix = true
      let send = clone(this.modelBiitrix.form)
      let nomeCompleto = ''

      send.anuncio_id = parseInt(this.$route.params.id)
      var gclid = JSON.parse(localStorage.getItem('gclid'))
      if (gclid) {
        send.gclid = gclid.value
      }
      send.DEAL.OPPORTUNITY = this.dados ? this.valorVeiculoComp.toString() : null
      send.DEAL.UF_CRM_1651977119 = window.location.pathname
      if (!this.usuarioSelecionado) {
        nomeCompleto = send.LEAD.NAME
        let nomesArr = nomeCompleto.split(' ')

        if (nomesArr[0]) send.LEAD.NAME = nomesArr[0]
        if (nomesArr[1]) {
          send.LEAD.SECOND_NAME = nomesArr[1]
        } else {
          send.LEAD.SECOND_NAME = nomesArr[0]
        }
        if (nomesArr[2]) {
          send.LEAD.LAST_NAME = nomesArr[2]
        } else {
          send.LEAD.LAST_NAME = nomesArr[0]
        }
      }

      if (this.dados) {
        send.DEAL.UF_CRM_1600860123 = this.dados.ano_fabricacao.toString() ?? 'Sem Informação'
        send.DEAL.UF_CRM_1687972601 = this.dados.estoque_local.nome ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600913222 = 'Sem Informação' /* Banco financiamento pre aprovado. */
        send.DEAL.UF_CRM_1600866673352 = this.dados.cambio_objeto ? this.dados.cambio_objeto.cambio_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600866627564 = 'Veículo'
        send.DEAL.UF_CRM_1600866641722 = this.dados.combustivel_objeto ? this.dados.combustivel_objeto.combustivel_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912767 = this.dados.cor_objeto ? this.dados.cor_objeto.cor_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600913257 = this.$options.filters.dateEng() ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600912795 = 'Sem Informação' /* Descrição veiculo para troca */
        send.DEAL.UF_CRM_1600913200 = 'Sim' /* Financiamento pré aprovado (Sim,Não). 'Sim' => 271 | 'Não' => 273 */
        send.DEAL.UF_CRM_1600860088 = process.browser ? window.location.href : 'Não possui Link'
        send.DEAL.UF_CRM_1600860229 = this.dados.marca_objeto && this.dados.marca_objeto.marca_nome ? this.dados.marca_objeto.marca_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1601578991 = this.dados.marca_objeto && this.dados.marca_objeto.marca_nome ? this.dados.marca_objeto.marca_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600860141 = this.dados.modelo_objeto && this.dados.modelo_objeto.modelo_nome ? this.dados.modelo_objeto.modelo_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912756 = this.dados.versao_objeto && this.dados.versao_objeto.versao_nome ? this.dados.versao_objeto.versao_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912782 = this.dados.acessorios_busca_nome ? this.dados.acessorios_busca_nome.join(', ') : 'Não possui Acessórios'
        send.DEAL.UF_CRM_1600860032 = this.dados.placa ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600912818 = this.dados ? this.valorVeiculoComp.toString() : '0'

        send.DEAL.UF_CRM_1602179548 = this.possuiCarroEntrada ? 'SIM' : 'NÃO'
        send.DEAL.UF_CRM_1600859514 = this.formaPagamento
        send.DEAL.UF_CRM_1606076503 = this.possuiCarroEntrada ? 831 : 833
        send.DEAL.UF_CRM_1611422640206 = this.possuiCarroEntrada ? 871 : 873
        if (this.possuiCarroEntrada) {
          const marcaUsado = this.listas['marca_id'][this.listas['marca_id'].findIndex(ind => this.modelVeiculosEntrada.form.marca_id === ind.id)]
          const modeloUsado = this.listas['modelo_id'][this.listas['modelo_id'].findIndex(ind => this.modelVeiculosEntrada.form.modelo_id === ind.id)]
          const versaoUsado = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)]

          // valor de mercado carro
          send.DEAL.UF_CRM_1600859637 = (this.possuiCarroEntrada && this.resultadosCalcularPreco && this.resultadosCalcularPreco.valor_varejo) ? parseInt(this.resultadosCalcularPreco.valor_varejo) : 0
          send.DEAL.UF_CRM_1600913053 = this.possuiCarroEntrada ? this.modelVeiculosEntrada.form.placa : ''
          send.DEAL.UF_CRM_603A9A8462094 = send.DEAL.UF_CRM_1600913053

          send.DEAL.UF_CRM_1600913067 = this.possuiCarroEntrada && marcaUsado ? (marcaUsado.nome + ' Cod.:' + this.modelVeiculosEntrada.form.marca_id).toString() : ''
          send.DEAL.UF_CRM_603A9A839CA41 = send.DEAL.UF_CRM_1600913067

          send.DEAL.UF_CRM_1600913084 = this.possuiCarroEntrada && modeloUsado && versaoUsado ? (modeloUsado.nome + ' Versão: ' + versaoUsado.nome).toString() : ''
          send.DEAL.UF_CRM_603A9A83E7E71 = send.DEAL.UF_CRM_1600913084

          send.DEAL.UF_CRM_1600913084 += this.possuiCarroEntrada ? (' Cod.:' + this.modelVeiculosEntrada.form.modelo_id + '-' + this.modelVeiculosEntrada.form.versao_id).toString() : ''
          // send.DEAL.UF_CRM_1600913106 = this.possuiCarroEntrada ? (this.modelVeiculosEntrada.form.ano_fabricacao + '/' + this.modelVeiculosEntrada.form.ano_modelo).toString() : ''

          send.DEAL.UF_CRM_1600913106 = this.possuiCarroEntrada ? (this.modelVeiculosEntrada.form.ano_fabricacao).toString() : ''
          send.DEAL.UF_CRM_603A9A843F6F0 = send.DEAL.UF_CRM_1600913106
          // valor pedido carro na troca
          send.DEAL.UF_CRM_1600913147 = this.possuiCarroEntrada ? parseInt(this.modelVeiculosEntrada.form.valor_mercado) : 0
          // valor do veiculo na troca
          send.DEAL.UF_CRM_1600912818 = this.possuiCarroEntrada ? parseInt(this.modelVeiculosEntrada.form.valor_mercado) : 0
          send.DEAL.UF_CRM_1600912818 = send.DEAL.UF_CRM_1600912818.toString()
          send.DEAL.UF_CRM_603A9A83C0BD5 = (this.modelVeiculosEntrada.form.km) ? (this.modelVeiculosEntrada.form.km).toString() : 0

          // DESCRICAO
          send.DEAL.UF_CRM_1600859649 = 'Ano/Mod: ' + (this.modelVeiculosEntrada.form.ano_fabricacao + '/' + this.modelVeiculosEntrada.form.ano_modelo).toString()
          if (this.resultadosCalcularPreco && this.resultadosCalcularPreco.valor_varejo) {
            send.DEAL.UF_CRM_1600859649 += '<br />. Valor no varejo: ' + this.resultadosCalcularPreco.valor_varejo.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
          send.DEAL.UF_CRM_1600859649 += '\n. Valor carro na troca: ' + this.modelVeiculosEntrada.form.valor_mercado.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })

          send.DEAL.UF_CRM_1600912795 = send.DEAL.UF_CRM_1600859649
        }

        // Fotos Veiculo
        if (this.dados.bem_arquivos) {
          this.dados.bem_arquivos.map((img, i) => {
            if (i >= 0) {
              send.DEAL.UF_CRM_1601928279578.push(process.env.arquivosUrl + img.url)
            }
            if (i === 0) {
              send.DEAL.UF_CRM_1603919743536 = process.env.arquivosUrl + img.url.trim('/')
            }
          })
        }
        send.DEAL.UF_CRM_1600861473041 = send.DEAL.UF_CRM_1601928279578.length > 0 ? send.DEAL.UF_CRM_1601928279578.toString() : 'Sem Informação'
        /* */
      } else {
        this.notificacao('aviso', 'Este Anuncio não existe')
        this.criandoLeadBiitrix = false
        return false
      }
      /* */

      if (!this.vendedorComp) {
        if (this.$q.platform.is.mobile) {
          send.DEAL.UF_CRM_1615324660540 = 1039
        } else {
          send.DEAL.UF_CRM_1615324660540 = 1037
        }
      } else {
        send.DEAL.UF_CRM_1615324660540 = 1041
      }

      send.DEAL.UF_CRM_5F961C5A0C6CE = this.$options.filters.dateBrSimples(this.modelCredereLead.form.birthdate)

      this.modelBiitrix.salvar(send).then((res) => {
        const dealId = res && res.dados && res.dados.deal_id ? res.dados.deal_id : null
        const leadId = res && res.dados && res.dados.lead_id ? res.dados.lead_id : null
        LocalStorage.set('dealId', dealId)
        LocalStorage.set('leadId', leadId)

        this.criandoLeadBiitrix = false
        this.salvandoPasso3 = 'ok'
        // if (!cancelarRedirect) {
        // this.notificacao('salvo', 'Mensagem enviada', 8000)

        // if (this.usuarioSelecionado) {
        //   if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
        //     LocalStorage.set('usuarioSelecionado', this.usuarioSelecionado)
        //   }

        //   setTimeout(() => {
        //     this.irRota({ name: 'painel-proposta', params: { id: this.$route.params.id, dealId } })
        //   }, 1500)
        // } else {
        const bitrixdadoscadastro = {
          nome: nomeCompleto,
          documento: send.LEAD.UF_CRM_1602009023,
          email: send.LEAD.EMAIL[0].VALUE,
          celular: send.LEAD.PHONE[0].VALUE,
          id: this.$route.params.id,
          publico: this.$route.params.publico
        }

        LocalStorage.set('bitrixdadoscadastro', bitrixdadoscadastro)

        this.notificacao('salvo', 'Mensagem enviada', 8000)
        // this.irRota({ name: 'login' })
        // }

        this.newModelBiitrix()
        // }

        if (passo) {
          this.criadoBitrixLead = true
        //   this.submitSimulacaoCredere(passo, dealId)
        }
      }).catch((error) => {
        this.criandoLeadBiitrix = false
        this.notificacao('erro', error.msg)
      })
    },
    updateBitrix (dados, aprovado = true) {
      let send = { DEAL: {} }

      send.DEAL.UF_CRM_1600913222 = dados.banco
      send.DEAL.UF_CRM_1603390619705 = dados.parcela
      send.DEAL.UF_CRM_1603390656415 = dados.valorPrimeiraParcela
      send.DEAL.UF_CRM_1600859585 = this.valorEntradaComp.toString()

      if (aprovado) {
        send.DEAL.UF_CRM_1600913200 = 271 // Sim
      } else {
        send.DEAL.UF_CRM_1600913222 = 'Sem informação'
        send.DEAL.UF_CRM_1600913200 = 273 // Nao
      }
      // send.DEAL.UF_CRM_1600913200 = 'Sim'

      // Id do Lead
      send.bitrix_lead_id = LocalStorage.getItem('leadId')
      send.bitrix_lead_id = send.bitrix_lead_id.toString()
      // Id do Deal
      send.deal_id = LocalStorage.getItem('dealId')

      // condition_id:
      // simulacao_id:
      // valorLiberado:

      this.modelBiitrix.salvar(send)
    },

    irRota (rota) {
      // let routeData = this.$router.resolve(rota)
      // window.location.href = routeData.href
      this.$router.push(rota)
    },
    voltarRota (rota) {
      this.$router.go(-1)
    },

    validarCep (value) {
      const cep = value
      if (cep !== null && cep.length === 9) {
        this.modelLogradouroCep = new LogradouroCepModel()
        this.modelLogradouroCep.recurso = this.modelLogradouroCep.recurso.replace('{cep}', cep)
        this.modelLogradouroCep
          .getListagem()
          .then(res => {
            if (res && res.dados) {
              var objCep = res.dados
              this.modelCredereLead.form.address.zip_code = objCep.cep
              this.modelCredereLead.form.address.street = objCep.logradouro
              this.modelCredereLead.form.address.district = objCep.bairro
              this.modelCredereLead.form.address.city = objCep.localidade
              this.modelCredereLead.form.address.state = objCep.uf
            } else this.notificacao('erro', 'CEP Inválido!')
          })
          .catch(error => {
            this.notificacao('erro', error.msg)
          })
      }
    },

    consultarPlaca (placa = '') {
      this.placaLocalizada = false
      // eslint-disable-next-line
      placa = placa ? placa.replace(/[\s-]/g, '').replace('-', '') : null
      if (placa && placa.length > 6) {
        const modelPlaca = new ConsultaPlacaIcarrosModel()
        this.notificacao('enviando', 'Consultando placa, aguarde.')
        modelPlaca.getHashedId(placa).then((res) => {
          if (res.erro === 1) {
            this.notificacao('aviso', 'Veículo não encontrado, preencha as informações do veiculo manualmente')
          } else {
            this.placaResponse(res.dados)
          }
        }).catch(() => {
          this.notificacao('aviso', 'Veículo não encontrado, preencha as informações do veiculo manualmente')
        })
      }
    },

    calcularPreco () {
      // this.$refs.formCrederePasso1.validate().then(success => {
      //   if (success) {
      //     let fipe = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)].codigo_fipe

      //     if (fipe === undefined) {
      //       fipe = 1
      //     } else {
      //       this.modelCalcularPreco.form.valor_fipe = null
      //     }

      //     const model = new PrecificadorModel()
      //     model
      //       .getListagem({
      //         params: {
      //           search: `codigo:${fipe};ano:${this.modelVeiculosEntrada.form.ano_modelo}`,
      //           searchJoin: 'and',
      //           orderBy: 'id',
      //           sortedBy: 'desc'
      //         }
      //       })
      //       .then(response => {
      //         this.calculandoPreco = true
      //         const aviso = this.notificacao('enviando')

      //         const send = clone(this.modelCalcularPreco.form)
      //         send.placa = this.modelVeiculosEntrada.form.placa
      //         send.marca_id = this.modelVeiculosEntrada.form.marca_id
      //         send.modelo_id = this.modelVeiculosEntrada.form.modelo_id
      //         send.ano_modelo = this.modelVeiculosEntrada.form.ano_modelo
      //         send.versao_id = this.modelVeiculosEntrada.form.versao_id
      //         send.combustivel_id = this.modelVeiculosEntrada.form.combustivel_id

      //         if (!this.modelCalcularPreco.form.valor_fipe || !this.placaLocalizada) {
      //           if (response && response.dados && response.dados[0] && response.dados[0].valor) {
      //             this.modelCalcularPreco.form.valor_fipe = (response.dados[0].valor).replace(/\D/g, '')
      //             send.valor_fipe = (response.dados[0].valor).replace(/\D/g, '')
      //           } else {
      //             // this.modelCalcularPreco.form.valor_fipe = 20000
      //             // send.valor_fipe = 20000
      //             aviso()
      //             this.calculandoPreco = false
      //             send.valor_fipe = 0
      //             this.modelVeiculosEntrada.form.valor_mercado = 0
      //             this.modelVeiculosEntrada.form.valor_maximo = 0
      //             this.modelVeiculosEntrada.form.valor_minimo = 0
      //             this.modelVeiculosEntrada.form.valor_varejo = 0
      //             this.resultadosCalcularPreco = null
      //             this.notificacao('erro', 'Não encontramos o veículo. Verifique os dados')
      //             return
      //           }
      //         }

      //         this.modelCalcularPreco.form = send

      //         this.modelCalcularPreco.getListagem({ params: send }).then((res) => {
      //           const r = res && res.dados ? res.dados : null

      //           if (r) {
      //             this.modelVeiculosEntrada.form.valor_maximo = parseInt(r.valor_maximo_final)
      //             this.modelVeiculosEntrada.form.valor_minimo = parseInt(r.valor_minimo_final)
      //             this.modelVeiculosEntrada.form.valor_mercado = parseInt(r.valor_trade_in)
      //             this.modelVeiculosEntrada.form.valor_varejo = parseInt(r.valor_price_guru)
      //             this.resultadosCalcularPreco = r
      //           }

      //           aviso()
      //           this.notificacao('salvo', 'Calculado com sucesso', 3000)
      //           this.calculandoPreco = false
      //         }).catch(error => {
      //           aviso()
      //           this.calculandoPreco = false
      //           this.notificacao('erro', error.msg)
      //         })
      //       })
      //       .catch(() => {
      //         this.forceRender++
      //       })
      //   } else {
      //     this.notificacao('erro', 'Todos os campos obrigatórios')
      //   }
      // })
    },

    placaResponse (res) {
      if (res) {
        this.limparCamposVeiculoEntrada()
        this.placaLocalizada = true
        this.modalveiculos = false
        this.limparVeiculoEntrada = false

        let camposResponse = clone(res)
        delete camposResponse['situacao_bem']

        let dados = res.dados_consulta
        delete dados.motor

        let camposGenericos = Object.keys(camposResponse).filter(campo => campo !== 'dados_consulta')
        camposGenericos.map(campo => {
          this.modelVeiculosEntrada.form[`${campo}_id`] = camposResponse[campo].id
        })
        this.modelVeiculosEntrada.form.cambio_id = dados['cambioAutomatico'] === 0 ? 2 : 1

        if (camposResponse && camposResponse.versao_precificadores && camposResponse.versao_precificadores.length > 0) {
          this.modelCalcularPreco.form.valor_fipe = this.$options.filters.soNumeros(camposResponse.versao_precificadores[0].valor).toString()
        }

        if (camposResponse && camposResponse.marca) {
          this.modelVeiculosEntrada.form.marca_nome = camposResponse.marca.nome
        }

        Object.keys(dados).map(campo => {
          switch (campo) {
            case 'marcaId':
              this.modelVeiculosEntrada.form.marca_id = dados[`${campo}`]
              break
            case 'anoFabricacao':
              this.modelVeiculosEntrada.form.ano_fabricacao = dados[`${campo}`]
              break
            case 'anoModelo':
              this.modelVeiculosEntrada.form.ano_modelo = dados[`${campo}`]
              break
            case 'combustivelId':
              this.modelVeiculosEntrada.form.combustivel_id = dados[`${campo}`]
              break
            default:
              break
          }
        })
        setTimeout(() => {
          this.limparVeiculoEntrada = true
        }, 3000)
      }
    },
    submitCrederePasso1 () {
      if (this.possuiCarroEntrada) {
        if (this.modelCalcularPreco.form.placa_uf) this.modelVeiculosEntrada.form.uf_placa = this.modelCalcularPreco.form.placa_uf
        if (this.modelCalcularPreco.form.km) this.modelVeiculosEntrada.form.km = this.modelCalcularPreco.form.km.toString()

        const send = clone(this.modelVeiculosEntrada.form)

        if (this.usuarioSelecionado && this.usuarioSelecionado.id !== null) send.usuario_id = this.usuarioSelecionado.id

        if (this.dados && this.dados.valor_fipe) {
          send.valor_fipe = this.dados.valor_fipe
        }

        if (!this.veiculoQuitado) {
          send.valor_saldo_devedor = this.veiculoSaldoDevedor
        }
        // if (this.valorVeiculoComp <= this.modelVeiculosEntrada.form.valor_mercado) {
        //   this.notificacao('aviso', 'Valor do veículo de entrada é igual ou superior ao veículo anunciado!', 3000)
        // } else {
        this.salvandoPasso1 = true
        const aviso = this.notificacao('enviando')
        send.simulacao = true
        if (!this.modelCredereProposta.form.veiculos_entradas_id) {
          this.modelVeiculosEntrada.salvar(send).then((res) => {
            this.salvarPasso2(res && res.dados && res.dados.id ? res.dados.id : null, aviso)
            // this.$refs.scrollpasso1.scrollIntoView({ behavior: 'smooth' })
          }).catch(error => {
            aviso()
            this.salvandoPasso1 = false
            this.notificacao('erro', error.msg)
          })
        } else {
          this.salvarPasso2(this.modelCredereProposta.form.veiculos_entradas_id, aviso)
        }
        // }
      } else {
        this.forcerRender++
        // this.salvandoPasso1 = 'ok'
        // this.passo1 = false
        // this.passo2 = true

        // document.querySelector('#scrollpasso1').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })

        this.salvarPasso2(null, null)
      }
    },
    salvarPasso2 (id, aviso) {
      if (id) {
        this.modelCredereProposta.form.veiculos_entradas_id = id
      }

      this.salvandoPasso1 = 'ok'
      this.passo1 = false
      this.passo2 = false
      this.salvandoPasso2 = 'ok'
      this.passo3 = true

      document.querySelector('#scrollpasso1').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
      // if (this.valorEntradaVeiculoComp >= this.valorVeiculoComp) {
      //   this.salvandoPasso1 = 'ok'
      //   this.passo1 = false
      //   this.passo2 = false
      //   this.salvandoPasso2 = 'ok'
      //   this.passo3 = true
      //   document.querySelector('#scrollpasso3').scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start',
      //     inline: 'start'
      //   })
      // } else {
      //   aviso()
      //   this.notificacao('salvo', 'Salvo com sucesso', 3000)
      //   this.forcerRender++
      //   this.salvandoPasso1 = 'ok'
      //   this.passo1 = false
      //   this.passo2 = true
      //   // if (this.$refs.scrollpasso1) {
      //   // setTimeout(() => {}, 1500)
      //   document.querySelector('#scrollpasso1').scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start',
      //     inline: 'start'
      //   })
      // }
    },

    submitCrederePasso2 () {
      if (((this.usuarioSelecionado && this.usuarioSelecionado.documento_lead && this.usuarioSelecionado.documento_lead.credere_lead_id) &&
        this.salvandoPasso3 === 'ok') ||
        this.criadoCredereLead) {
        this.simulacaoIdCredere = null
        this.encontrouSimulacao = false
        this.tempo = 20
        this.progress = 0.00
        this.salvandoPasso2 = true
        this.submitSimulacao('2')
        document.querySelector('#scrollpasso2').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      } else {
        this.salvandoPasso2 = 'ok'
        this.passo2 = false
        this.passo3 = true
        document.querySelector('#scrollpasso2').scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    },

    submitCrederePasso3 () {
      this.salvandoPasso3 = true
      const aviso = this.notificacao('enviando')

      const send = clone(this.modelCredereLead.form)

      if (send.phone_number) {
        send.phone_number = this.$options.filters.soNumeros(send.phone_number)
      }

      send.retrieve_gender = 'M'
      delete send.address
      // if (this.formaPagamento === 113) {
      // if (this.criadoCredereLead) {
      //   this.submitSimulacao('3')
      // } else {
      //   this.modelCredereLead.salvar(send).then((res) => {
      this.criadoCredereLead = true
      aviso()
      this.submitSimulacao('3')
      //   }).catch(error => {
      //     aviso()
      //     this.salvandoPasso3 = false
      //     this.notificacao('erro', error.msg)
      //   })
      // }
      // } else {
      //   this.modelBiitrix.form.LEAD.NAME = this.modelCredereLead.form.name ? this.modelCredereLead.form.name : (this.usuarioSelecionado && this.usuarioSelecionado.nome) ? this.usuarioSelecionado.nome : 'Sem Nome'
      //   this.modelBiitrix.form.LEAD.UF_CRM_1602009023 = this.$options.filters.soNumeros(this.modelCredereLead.form.cpf_cnpj ? this.modelCredereLead.form.cpf_cnpj : (this.usuarioSelecionado && this.usuarioSelecionado.documento) ? this.usuarioSelecionado.documento : '1')
      //   this.modelBiitrix.form.LEAD.EMAIL[0].VALUE = this.modelCredereLead.form.email ? this.modelCredereLead.form.email : (this.usuarioSelecionado && this.usuarioSelecionado.email) ? this.usuarioSelecionado.email : `${this.modelBiitrix.form.LEAD.UF_CRM_1602009023}@netcaros.com.br`
      //   this.modelBiitrix.form.LEAD.PHONE[0].VALUE = this.$options.filters.soNumeros(this.modelCredereLead.form.phone_number ? this.modelCredereLead.form.phone_number : (this.usuarioSelecionado && this.usuarioSelecionado.celular) ? this.usuarioSelecionado.celular : '1')
      //   this.criarLeadBiitrix(true, '3')
      //   if (this.criadoBitrixLead) {
      //     this.salvandoPasso3 = 'ok'
      //     this.forcerRender++
      //     this.passo3 = false
      //     this.passo4 = true
      //   }
      // }
    },

    submitSimulacao (passo) {
      // if (this.criadoBitrixLead) {
      //   this.submitSimulacaoCredere(passo, LocalStorage.getItem('dealId'))
      // } else {
      this.modelBiitrix.form.LEAD.NAME = this.modelCredereLead.form.name ? this.modelCredereLead.form.name : (this.usuarioSelecionado && this.usuarioSelecionado.nome) ? this.usuarioSelecionado.nome : 'Sem Nome'
      this.modelBiitrix.form.LEAD.UF_CRM_1602009023 = this.$options.filters.soNumeros(this.modelCredereLead.form.cpf_cnpj ? this.modelCredereLead.form.cpf_cnpj : (this.usuarioSelecionado && this.usuarioSelecionado.documento) ? this.usuarioSelecionado.documento : '1')
      this.modelBiitrix.form.LEAD.EMAIL[0].VALUE = this.modelCredereLead.form.email ? this.modelCredereLead.form.email : (this.usuarioSelecionado && this.usuarioSelecionado.email) ? this.usuarioSelecionado.email : `${this.modelBiitrix.form.LEAD.UF_CRM_1602009023}@netcaros.com.br`
      this.modelBiitrix.form.LEAD.PHONE[0].VALUE = this.$options.filters.soNumeros(this.modelCredereLead.form.phone_number ? this.modelCredereLead.form.phone_number : (this.usuarioSelecionado && this.usuarioSelecionado.celular) ? this.usuarioSelecionado.celular : '1')
      this.criarLeadBiitrix(false, passo)
      // }
    },
    async submitSimulacaoCredere (passo, dealId) {
      if ((this.valorVeiculoComp - this.valorEntradaComp) > this.valorMinimoFinanciamento) {
        this[`salvandoPasso${passo}`] = true
        const aviso = this.notificacao('enviando')

        if (dealId) this.modelCredereSimulacao.form.deal_id = dealId.toString()

        const combustivel = this.dados.combustivel_objeto ? this.dados.combustivel_objeto.combustivel_nome : ''
        const send = clone(this.modelCredereSimulacao.form)

        if (this.dados && this.dados.codigo_molicar) {
          send.vehicle.vehicle_molicar_code = this.dados.codigo_molicar
        } else {
          this.notificacao('aviso', 'Codigo molicar precisar ser informado no cadastro desse anuncio! Entre em contato com suporte do sistema!', 10000)
          return false
        }

        if (this.usuarioSelecionado && this.usuarioSelecionado.id) {
          send.usuario_id = this.usuarioSelecionado.id
        }
        if (this.usuarioSelecionado && this.usuarioSelecionado.documento) {
          send.retrieve_lead.cpf_cnpj = this.usuarioSelecionado.documento
        }

        if (!(this.usuarioSelecionado && this.usuarioSelecionado.documento) && this.modelCredereLead.form.cpf_cnpj) {
          send.retrieve_lead.cpf_cnpj = this.modelCredereLead.form.cpf_cnpj
        }

        send.seller_cpf = send.retrieve_lead.cpf_cnpj
        send.assets_value = this.dados ? (parseInt(parseFloat(this.valorVeiculoComp).toFixed(2) * 100)).toString() : '0'
        // send.vehicle.vehicle_molicar_code = this.dados ? this.dados.codigo_molicar : '01906110-9'
        send.vehicle.licensing_uf = this.dados ? (this.dados.placa_uf ? this.dados.placa_uf : 'SP') : 'SP'
        send.vehicle.manufacture_year = this.dados ? this.dados.ano_fabricacao.toString() : '0'
        send.vehicle.model_year = this.dados ? this.dados.ano_modelo.toString() : '0'
        send.vehicle.zero_km = this.dados && this.dados.km === 0 ? '1' : '0'
        send.vehicle.asset_value = this.dados ? parseInt(parseFloat(this.valorVeiculoComp).toFixed(2) * 100).toString() : '0'
        send.anuncio_id = parseInt(this.$route.params.id)
        send.simulacao = true

        const combustivelEncontrado = this.combustiveisOptions.find(e => combustivel.includes(e.label))
        send.vehicle.retrieve_fuel_type = combustivelEncontrado ? combustivelEncontrado.credere_identifier : '1'
        send.conditions.map((_, index) => {
          send.conditions[index].down_payment = parseInt(this.valorEntradaComp.toFixed(2) * 100).toString() /* Valor Entrada */
          send.conditions[index].financed_amount = (parseInt((this.valorVeiculoComp - this.valorEntradaComp) * 100)).toString() /* Valor total - Valor Entrada */
        })
        this.modelCredereSimulacao.recurso = 'credere/simulacoes'
        await this.modelCredereSimulacao.salvar(send).then((res, err) => {
          if (this.salvandoPasso2 !== 'ok') {
            this.salvandoPasso2 = 'ok'
          }
          if (passo === '3') {
            document.querySelector('#scrollpasso3').scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            })
          }
          this.tratarResultado(res, passo)
          aviso()
        }).catch(error => {
          console.log('entrei aqui catch', error)
          aviso()
          // this[`salvandoPasso${passo}`] = false
          this.tratarResultado({ dados: null }, passo)
          this.encontrouSimulacao = true
          // this.notificacao('erro', error.msg)
        })

        if (this.interval) {
          clearInterval(this.interval)
        }

        this.interval = setInterval(() => {
          this.tempo--
          if (this.progress >= 1) {
            this.progress = 1
            this.buffer = 1
            this.tempo = 0
            clearInterval(this.interval)
            return
          }
          this.progress = Math.min(1, this.buffer, this.progress + 0.05)
        }, 1000)

        if (!this.encontrouSimulacao) {
          await this.buscarResultadoSimulacao(2, passo)
        }
      } else {
        if (passo === '3') {
          document.querySelector('#scrollpasso3').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
        }
        this[`salvandoPasso${passo}`] = 'ok'
        this[`passo${passo}`] = false
        this.forcerRender++
        this.passo3 = false
        this.passo4 = true
        this.encontrouSimulacao = true
      }
    },
    async buscarResultadoSimulacao (loop, passo) {
      for (let i = 1; i <= loop; i += 1) {
        if (!this.encontrouSimulacao) {
          await this.timer(8000, passo)
          if (loop === i) {
            this.encontrouSimulacao = true
          }
        }
      }
    },
    async timer (ms, passo) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.simulacaoIdCredere) {
            this.modelCredereSimulacaoBuscar.getId({ id: this.simulacaoIdCredere + '/token' }).then(result => {
              result.dados.data.conditions.map(ele => {
                if (ele.process_task && ele.process_task.result && ele.process_task.result.success === true && (ele.pre_approval_status === 2 || ele.pre_approval_status === 3)) {
                  if (!this.encontrouSimulacao) {
                    this.tratarResultado(result, passo)
                  }
                  // this.encontrouSimulacao = true
                }
              })
              resolve()
            }).catch(() => {
              resolve()
            })
          } else {
            resolve()
          }
        }, ms)
      })
    },
    tratarResultado (res, passo) {
      let resquet = res.dados && res.dados.request_log && res.dados.request_log.response ? JSON.parse(res.dados.request_log.response) : null

      let parcelas = []

      if (resquet === null) {
        resquet = res.dados ?? null
      }
      let resultSimulacao = []
      this.passo4 = false

      if ((this.valorVeiculoComp - this.valorEntradaComp) > this.valorMinimoFinanciamento) {
        if (resquet && resquet.data && resquet.data.conditions) {
          if (this.simulacaoIdCredere === null && res.dados && res.dados.credere_simulacao_id) {
            this.simulacaoIdCredere = res.dados.credere_simulacao_id
          }
          if (this.simulacaoId === null && res.dados && res.dados.id) {
            this.simulacaoId = res.dados.id
          }
          this.bancoPrioridade.map(bank => {
            resquet.data.conditions.map(ele => {
              if (ele.bank.febraban_code === bank.id) {
                if (ele.process_task && ele.process_task.result && ele.process_task.result.success === true && (ele.pre_approval_status === 2 || ele.pre_approval_status === 3)) {
                  const add = {
                    simulacao_id: this.simulacaoId,
                    condition_id: ele.id ? ele.id : null,
                    parcela: parseInt(ele.installments),
                    status_aprovacao: ele.pre_approval_status,
                    banco: ele.bank && ele.bank.name ? ele.bank.name : null,
                    valorPrimeiraParcela: parseFloat(ele.first_installment_value / 100),
                    valorLiberado: parseFloat(ele.financed_amount / 100)
                  }
                  resultSimulacao.push(add)
                }
              }
            })
          })
        }

        const parce12 = resultSimulacao.filter(e => e.parcela === 12)
        const parce24 = resultSimulacao.filter(e => e.parcela === 24)
        const parce36 = resultSimulacao.filter(e => e.parcela === 36)
        const parce48 = resultSimulacao.filter(e => e.parcela === 48)
        const parce60 = resultSimulacao.filter(e => e.parcela === 60)

        const newResultSimulacao = []

        if (parce12 && parce12.length > 0) newResultSimulacao.push(parce12.sort(this.sortValorPrimeiraParcela)[0])
        else {
          if (parcelas.indexOf(12) === -1) {
            parcelas.push(12)
            this.calcularValorParcela(12)
          }
        }

        if (parce24 && parce24.length > 0) newResultSimulacao.push(parce24.sort(this.sortValorPrimeiraParcela)[0])
        else {
          if (parcelas.indexOf(24) === -1) {
            parcelas.push(24)
            this.calcularValorParcela(24)
          }
        }

        if (parce36 && parce36.length > 0) newResultSimulacao.push(parce36.sort(this.sortValorPrimeiraParcela)[0])
        else {
          if (parcelas.indexOf(36) === -1) {
            parcelas.push(36)
            this.calcularValorParcela(36)
          }
        }

        if (parce48 && parce48.length > 0) newResultSimulacao.push(parce48.sort(this.sortValorPrimeiraParcela)[0])
        else {
          if (parcelas.indexOf(48) === -1) {
            parcelas.push(48)
            this.calcularValorParcela(48)
          }
        }

        if (parce60 && parce60.length > 0) newResultSimulacao.push(parce60.sort(this.sortValorPrimeiraParcela)[0])
        else {
          if (parcelas.indexOf(60) === -1) {
            parcelas.push(60)
            this.calcularValorParcela(60)
          }
        }
        this.resultSimulacao = newResultSimulacao
      }
      this[`salvandoPasso${passo}`] = 'ok'
      this[`passo${passo}`] = false
      this.forcerRender++
      this.passo3 = false
      this.passo4 = true

      // if (this.$refs.scroolpasso3) {
      //   this.$refs.scroolpasso3.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      //   setTimeout(() => {
      //     window.scroll(0, window.scrollY - 128)
      //   }, 1000)
      // }
    },

    sortValorPrimeiraParcela (a, b) {
      return a.valorPrimeiraParcela < b.valorPrimeiraParcela ? -1 : a.valorPrimeiraParcela > b.valorPrimeiraParcela ? 1 : 0
    },

    calcularValorParcela (qtdParcelas) {
      const params = clone(this.modelCalcularValorParcela.form)

      params.ano_modelo = this.dados ? this.dados.ano_modelo.toString() : 0
      params.valor = (parseInt((this.valorVeiculoComp - this.valorEntradaComp) * 100) / 100).toFixed(2)
      params.placa_uf = this.dados ? this.dados.placa_uf ? this.dados.placa_uf : 'SP' : 'SP'
      params.qtd_parcelas = qtdParcelas
      params.porcentagem_entrada = parseInt(this.valorEntradaComp / this.valorVeiculoComp * 100)

      this.modelCalcularValorParcela.getListagem({ params }).then(res => {
        if (res.dados) {
          const add = {
            simulacao_id: null,
            condition_id: qtdParcelas,
            parcela: qtdParcelas,
            banco: null,
            valorPrimeiraParcela: res.dados.valor_parcelas,
            valorLiberado: res.dados.valor_parcelas
          }
          if (this.resultSimulacao.findIndex(ind => ind.parcela === qtdParcelas) === -1) {
            this.resultSimulacao.push(add)
          }
        }
      }).catch(error => {
        this.notificacao('erro', error.msg)
      })
    },

    parcelaSelecionada (item) {
      this.formPasso4 = item
      if (item.condition_id && item.simulacao_id) {
        this.updateBitrix(item)
      } else {
        this.updateBitrix(item, false)
        // this.notificacao('aviso', 'Esta parcela não foi Pré Aprovado!')
      }
    },
    submitCrederePasso4 () {
      const passo4 = clone(this.modelCredereProposta.form)

      passo4.usuario_id = this.usuarioSelecionado ? this.usuarioSelecionado.id : null

      if (this.formPasso4) {
        passo4.simulacao_id = this.formPasso4.simulacao_id
        passo4.condition_id = this.formPasso4.condition_id
      } else {
        if (!(Math.abs(this.valorEntradaComp - this.valorVeiculoComp) <= this.valorMinimoFinanciamento)) {
          this.notificacao('aviso', 'Selecione uma parcela!')
        }
      }
      this.salvandoPasso4 = true
      this.notificacao('enviando')

      /* ******************* */
      // Enviar para outro lado
      const passo1 = clone(this.modelVeiculosEntrada.form)
      passo1.possuiCarroEntrada = this.possuiCarroEntrada
      passo1.formaPagamento = this.formaPagamento
      const passo2 = clone(this.formPasso2)
      const calcularPreco = clone(this.modelCalcularPreco.form)
      const simulacaoForm = clone(this.modelCredereSimulacao.form)

      const passosCredereDetalhes = {
        passo1,
        passo2,
        passo4,
        calcularPreco,
        simulacaoForm,
        veiculoQuitado: this.veiculoQuitado,
        veiculoSaldoDevedor: this.veiculoSaldoDevedor,
        id: this.$route.params.id,
        publico: this.$route.params.publico
      }
      LocalStorage.set('passosCredereDetalhes', passosCredereDetalhes)

      // this.$store.commit('depois/putData', this.modelCredereLead.form)
      if (this.usuarioSelecionado) {
        this.irRota({ name: 'painel-proposta', params: { id: this.$route.params.id } })

        if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
          LocalStorage.set('usuarioSelecionado', this.usuarioSelecionado)
        }
      } else {
        this.notificacao('salvo', 'Proposta enviada', 8000)
        // this.irRota({ name: 'login' })
      }
      /* ******************* */
    },

    pesquisarUsuario () {
      this.pesquisandoUsuario = true
      const aviso = this.notificacao('enviando')

      const search = { documento: this.cpfUsuarioSelecionado }

      this.modelUsuario.getListagem({ params: { buscaGrid: search } }).then((res) => {
        this.pesquisandoUsuario = false
        const r = res.dados && res.dados.length > 0 ? res.dados[0] : null

        this.responseUsuarioSelecionado(r)
      }).catch(error => {
        aviso()
        this.pesquisandoUsuario = false
        this.notificacao('erro', error.msg)
      })
    },

    responseUsuarioSelecionado (r) {
      if (r) {
        this.usuarioSelecionado = r
        LocalStorage.set('usuarioSelecionado', this.usuarioSelecionado)
        this.modelBiitrix = new BiitrixModel(this.usuarioSelecionado)
        LocalStorage.remove('passosCredereDetalhes')
        this.limparCamposVeiculoEntrada()
        this.passo1 = true
        this.passo2 = false
        this.passo3 = false
        this.passo4 = false
        this.salvandoPasso1 = false
        this.salvandoPasso2 = false
        this.salvandoPasso3 = false
        this.salvandoPasso4 = false
        this.forceRender++
      } else this.notificacao('aviso', 'Usuário não encontrado!')

      if (this.usuarioSelecionado && this.usuarioSelecionado.documento_lead && this.usuarioSelecionado.documento_lead.credere_lead_id) {
        this.salvandoPasso3 = 'ok'
      }

      this.modelCredereLead.form.birthdate = this.usuarioSelecionado ? this.usuarioSelecionado.data_nascimento : null
    },

    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos || incluir === obj['id']
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    /* */

    // cadastrar cliente
    async cadastrarCliente () {
      let password = Math.random().toString(36).slice(-8)
      let form = Object.assign({}, {
        ...this.usuarioCoreModel.form,
        ...this.formUsuario,
        ...{
          senha: password,
          senha_confirmation: password
        }
      })
      this.pesquisandoUsuario = true
      try {
        const retorno = await this.usuarioCoreModel.cadastrar({ ...form, situacao_id: 1, senha_por_email: 1 })
        // if (this.$refs.cadastro) {
        //   this.$refs.cadastro.resetValidation()
        // }

        if (retorno.erro === 0 && retorno.dados) {
          this.responseUsuarioSelecionado(retorno.dados)
          this.modalCadastroCliente = false
          this.notificacao('salvo', 'Usuário salvo e selecionado com sucesso!')
        }

        this.pesquisandoUsuario = false
      } catch (erros) {
        if (erros.length > 0) {
          erros.map((erro) => this.notificacao('erro', erro))
        } else {
          this.notificacao('erro', erros)
        }
        this.pesquisandoUsuario = false
      }
    }
    /* fim - cadastrar cliente */
  },
  filters: {
    tipoNome (tipo) {
      if (tipo) {
        if (tipo === 'tipo_retomada_id') {
          return 'Origem de entrada'
        }
        let nome = tipo.replace('_id', '')
        if (nome) {
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    imagemOriginal (endereco) {
      if (typeof endereco === 'undefined' || endereco === null) {
        return ''
      }
      if (typeof endereco === 'string') {
        let img = endereco.split('.')
        const extensao = img.pop()
        let nome = img.join('.')
        nome = `${nome}_original`
        img = `${nome}.${extensao}`
        return img
      } else {
        return ''
      }
    },
    numeroParaMoeda (valor) {
      if (valor && valor > 0) {
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
        return formatter.format(valor)
      } else {
        return 0
      }
    }
  }
}
